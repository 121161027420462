export const AppConfig = {
  NotificationTimeout: 2000,
};

export const TableSchemas = {
  Permissions: 'permissions',
  Quiz: 'quiz',
  SubjectList: 'subject_list',
  Subjects: 'subjects',
  Subscribers: 'subscribers',
  Users: 'users',
};
