import { AdminRoute } from '@/modules/admin';
import { UserRoute } from '@/modules/user';
import { finalize } from 'rxjs/operators';

/* eslint-disable no-unused-vars */
const listColleges = [
  { text: 'Đại học Kinh tế TP.HCM' },
  { text: 'Đại học Bách Khoa TP.HCM' },
  { text: 'Đại học Khoa Học Tự Nhiên TP.HCM' },
  { text: 'Đại học Tài chính - Marketing TP.HCM' },
  { text: 'Đại học Luật TP.HCM' },
  { text: 'Khác' },
];

const LoginPage = {
  data() {
    const { userInfo = {}, token } = this.$authentication;
    const { college, faculty } = userInfo;

    return {
      listColleges,

      isLoading: false,
      isLogged: !!token,

      model: { college, faculty, class: userInfo.class },
      requiredInformation: this.$authentication.missRequiredInformation,

      errors: {},
    };
  },
  created() {
    this.checkValid();
  },
  mounted() {
    const { collegeInput } = this.$refs;
    if (collegeInput) {
      const collegeInputElement = collegeInput.$el.querySelector('.p-inputtext');

      collegeInputElement.addEventListener('focus', () => {
        if (!collegeInput.overlayVisible) {
          collegeInput.show();
        }
      });

      collegeInputElement.addEventListener('blur', () => {
        setTimeout(() => {
          this.validateInformationForm({ target: { name: 'college' } });
        }, 500);
      });
    }
  },
  methods: {
    onClickLoginBtn() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      this.$authentication
        .loginWithGoogle()
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          () => {
            this.isLogged = true;
            this.model = this.$authentication.userInfo;
            this.requiredInformation = this.$authentication.missRequiredInformation;

            this.checkValid();
          },
          e => {
            const { message = 'Error is occurred!' } = e;
            this.$notification.error(message);
          },
        );
    },
    validateInformationForm(event) {
      const errors = {};

      if (!this.model.college || !this.model.college.trim()) {
        errors.college = 'Vui lòng nhập trường';
      }

      if (!this.model.faculty || !this.model.faculty.trim()) {
        errors.faculty = 'Vui lòng nhập khoa';
      }

      if (!this.model.class || !this.model.class.trim()) {
        errors.class = 'Vui lòng nhập khóa';
      }

      if (event) {
        const { name } = event.target || {};
        this.errors[name] = errors[name];
        this.errors = { ...this.errors };
      } else {
        this.errors = errors;
      }

      return !Object.keys(errors).length;
    },

    submitInformationForm() {
      if (this.validateInformationForm()) {
        this.isLoading = true;
        this.$firebase.db.collection('user_tracking').doc(this.$authentication.userInfo.email).set({});
        this.$authentication.userInfo = Object.assign({ createdAt: new Date().toString() }, this.$authentication.userInfo, this.model);

        this.$authentication
          .save()
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
          .subscribe(
            () => {
              this.requiredInformation = this.$authentication.missRequiredInformation;
              this.checkValid();
            },
            e => {
              const { message = 'Có lỗi rồi!' } = e;
              this.$notification.error(message);
            },
          );
      }
    },

    checkValid() {
      if (this.isLogged && !this.requiredInformation) {
        let redirectUrl = this.$route.query.redirectUrl;
        if (!redirectUrl) {
          redirectUrl = this.$authentication.isAdmin ? AdminRoute.Base : UserRoute.Base;
        }

        this.$router.push(redirectUrl);
      }
    },
    test(event) {
      console.log(event);

      // @ts-ignore
    },
  },
};

export default LoginPage;
