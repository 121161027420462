import { adminRoutes } from '@/modules/admin';
import { NotFoundPage } from '@/modules/app/pages/not-found';
import { authenticationRoutes, AuthRoute } from '@/modules/authentication';
import { userRoutes } from '@/modules/user';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  { path: '/', redirect: AuthRoute.Login },
  ...authenticationRoutes,
  ...userRoutes,
  ...adminRoutes,
  { path: '/404', name: 'NotFound', component: NotFoundPage },
  { path: '*', redirect: '/404' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
