const PLUS_ONLY = /\+.*$/;
const PLUS_AND_DOT = /\.|\+.*$/g;
const normalizableProviders = {
  'gmail.com': {
    cut: PLUS_AND_DOT,
  },
  'googlemail.com': {
    cut: PLUS_AND_DOT,
    aliasOf: 'gmail.com',
  },
  'hotmail.com': {
    cut: PLUS_ONLY,
  },
  'live.com': {
    cut: PLUS_AND_DOT,
  },
  'outlook.com': {
    cut: PLUS_ONLY,
  },
};

function normalizeEmail(eMail) {
  if (typeof eMail != 'string') {
    throw new TypeError('normalize-email expects a string');
  }

  var email = eMail.toLowerCase();
  var emailParts = email.split(/@/);

  if (emailParts.length !== 2) {
    return eMail;
  }

  var username = emailParts[0];
  var domain = emailParts[1];

  if (normalizableProviders[domain]) {
    if (normalizableProviders[domain]['cut']) {
      username = username.replace(normalizableProviders[domain].cut, '');
    }
    if (normalizableProviders[domain]['aliasOf']) {
      domain = normalizableProviders[domain].aliasOf;
    }
  }

  return username + '@' + domain;
}

export const miscUtil = {
  normalizeEmail,
};
