export default {
  props: ['question', 'selectedAnswer'],
  data() {
    return {
      clicked: false,
      selectedValue: null,
      firebase: this.$firebase.rt.ref("user_tracking/" + this.$authentication.userInfo.email.replace('@gmail.com','').replaceAll('.','!'))
    }
  },
  methods: {
    getAnswers(answers) {
      return Object.keys(answers)
        .sort()
        .map(answer => {
          return {
            text: answers[answer],
            value: answer,
          };
        });
    },
    answerClass(value) {
      if (value === this.question.correctAnswer) return 'correct'
      else if (this.selectedAnswer && value === this.selectedAnswer.selected) return 'incorrect'
      else return ''
    }
  },
}
