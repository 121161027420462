import { LoginPage } from './pages';

export const AuthRoute = {
  Login: '/auth/login',
};

export const authenticationRoutes = [
  {
    path: AuthRoute.Login,
    name: 'Login',
    component: LoginPage,
  },
];
