import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { BootstrapVue } from 'bootstrap-vue';
import PrettyCheckbox from 'pretty-checkbox-vue';
import AutoComplete from 'primevue/autocomplete';
import Carousel from 'primevue/carousel';
import PrimeVue from 'primevue/config';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Menu from 'primevue/menu';
import ProgressSpinner from 'primevue/progressspinner';
import Sidebar from 'primevue/sidebar';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Rating from 'primevue/rating';
import Vue from 'vue';

import { FirebaseService, NotificationService } from './services';

export * from './services';

const components = {
  AutoComplete,
  Carousel,
  Dialog,
  Dropdown,
  InputText,
  Menu,
  ProgressSpinner,
  Sidebar,
  Rating,
  Toast,
  'font-awesome-icon': FontAwesomeIcon,
};

const services = [FirebaseService, ToastService, NotificationService];

library.add(far);
library.add(fas);

export function initializeCore() {
  Vue.use(PrimeVue);
  Vue.use(BootstrapVue);
  Vue.use(PrettyCheckbox);


  for (const key in components) {
    Vue.component(key, components[key]);
  }

  services.forEach(x => Vue.use(x));
}
