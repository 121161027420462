import { combineLatest } from 'rxjs';

const SubjectsComponent = {
  data() {
    return {
      showModal: false,
      quizId: undefined,
      subjects: [],
      quizMap: {},
      images: {
        empty: '/images/empty.jpg',
        KT001: '/images/KT001.png',
        KT002: '/images/KT002.png',
        KT003: '/images/KT003.png',
        KT004: '/images/KT004.png',
      },
      responsiveOptions: [
        {
          numVisible: 4,
          numScroll: 4,
        },
        {
          breakpoint: '1200px',
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: '900px',
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: '620px',
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },
  computed: {
    userInfo() {
      return this.$authentication.userInfo;
    },
  },
  created() {
    this.unsubscribeList = [];
    this.unsubscribeList.push(
      this.$subject.ksaSubjectsDoc.onSnapshot(snapshot => {
        const data = snapshot.data();
        this.$store.commit('INIT', data);
        this.subjects = Object.keys(data)
          .map(id => ({ id, name: data[id] }))
          .sort((a, b) => a.id.localeCompare(b.id));
        this.getListQuizzes();
      }),
    );
  },
  beforeDestroy() {
    this.unsubscribeList.forEach(unsubscribe => unsubscribe());
  },
  methods: {
    getListQuizzes() {
      const quizMap = {};

      /**@type any[] */
      const listOb$ = this.subjects.map(x => this.$subject.getListQuizzes(x.id));
      combineLatest(listOb$).subscribe(result => {
        // @ts-ignore
        const listDocs = result.map(x => x.docs);
        listDocs.forEach((listQuiz, index) => {
          const subject = this.subjects[index];

          listQuiz.forEach(x => {
            const quizId = x.id;

            if (!quizMap[subject.id]) {
              quizMap[subject.id] = [];
            }

            quizMap[subject.id].push(quizId);
          });

          this.quizMap = quizMap;
        });
      });
    },

    getQuizCode(quizId, subjectId) {
      return quizId.replace(`${subjectId}_`, '');
    },

    visibleSubject(subjectName) {
      return !subjectName.startsWith('_');
    },

    toggleModal(quizId) {
      this.quizId = quizId;
      this.showModal = !this.showModal;
    },
  },
  mounted() {
    document.title = 'Tabbook - Home';
  },
};

export default SubjectsComponent;
