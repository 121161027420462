export default {
  props: ['subject'],
  data() {
    return {
      tests: []
    }
  },
  methods: {
    extractChapter(chapter) {
      return Object.keys(chapter).map(c => 'Chương ' + c + ": " + chapter[c]).join("\n")
    },

    formatDate(date){
      let temp = date.split(' ')
      return temp[4] + ' ' + temp[1] + '-' + temp[2] + '-' + temp[3]
    },

    editTest(code){
      let param = this.subject.id + '_' + code
      this.$router.push({name: 'UploadWithId', params: {id : param}})
    },

    deleteTest(code){
      let param = this.subject.id + '_' + code
      this.$firebase.db.collection('quiz').doc(param).delete()
        .then(() => {
          let temp = {}
          temp[code] = this.$firebase.FieldValue.delete()
          this.$firebase.db.collection('quiz').doc(this.subject.id).update(temp)
            .then(() => location.reload()).catch(() => {
            this.$toast.add({severity:'error', summary: 'Failed', detail:'Cannot connet to server', life: 3000});
          })
        }).catch(() => {
        this.$toast.add({severity:'error', summary: 'Failed', detail:'Cannot connet to server', life: 3000});
      })
    }
  },

}
