import { AuthRoute } from '@/modules/authentication';

const NotFoundComponent = {
  data() {
    return {
      userMenuItems: [
        {
          label: 'Đăng Xuất',
          icon: 'fas fa-sign-out-alt',
          command: () => {
            this.logout();
          },
        },
      ],
      visibleMenu: false,
    };
  },
  computed: {
    userInfo() {
      return this.$authentication.userInfo;
    },
  },
  created() {},
  methods: {
    logout() {
      this.$authentication.logout().subscribe(() => {
        this.$router.push(AuthRoute.Login);
      });
    },
    toggleUserMenu(e) {
      this.$refs.userMenu.toggle(e);
    },
    toHome() {
      this.$router.push({ name: 'Subjects' }).catch(() => {});
    },
  },
  watch: {
    $route() {
      if (this.visibleMenu) {
        this.visibleMenu = false;
      }
    },
  },
};

export default NotFoundComponent;
