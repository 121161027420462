import { TableSchemas } from '@/configs';
import { BaseService } from '@/modules/core';
import firebase from 'firebase/app';
import { from, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';

import { AuthenticationStorageKeys } from '../constants';

export class AuthenticationService extends BaseService {
  /** @type {AuthenticationService} */
  static _instance;

  // --------------------------------------------------

  static install(Vue) {
    if (!AuthenticationService._instance) {
      AuthenticationService._instance = new AuthenticationService(Vue);
    }

    Vue.prototype.$authentication = AuthenticationService._instance;
  }

  // --------------------------------------------------

  /** @type {firebase.firestore.DocumentReference<firebase.firestore.DocumentData>} */
  _currentUserRef;

  /** @type {firebase.firestore.DocumentReference<firebase.firestore.DocumentData>} */
  _permissionsRef;

  /** @type {string} */
  token;

  userInfo;

  permissions;

  // --------------------------------------------------

  /** @type {import('@/modules/core').FirebaseService} */
  get $firebase() {
    return this._vue.prototype.$firebase;
  }

  get currentUserRef() {
    if (!this.token || !this.userInfo) {
      return undefined;
    }

    if (!this._currentUserRef) {
      const email = this.userInfo.email;
      this._currentUserRef = this.$firebase.db.collection(TableSchemas.Users).doc(email);
    }

    return this._currentUserRef;
  }

  get permissionsRef() {
    if (!this.token || !this.userInfo) {
      return undefined;
    }

    if (!this._permissionsRef) {
      const email = this.userInfo.email;
      this._permissionsRef = this.$firebase.db.collection(TableSchemas.Permissions).doc(email);
    }

    return this._permissionsRef;
  }

  get missRequiredInformation() {
    const requiredInformation = ['email', 'firstName', 'lastName', 'college', 'faculty', 'class'];
    const result = requiredInformation.some(x => !this.userInfo?.[x]);

    return result;
  }

  get isAdmin() {
    return !!this.permissions?.admin;
  }

  // --------------------------------------------------

  constructor(Vue) {
    super(Vue);
  }

  // --------------------------------------------------

  checkExist() {
    return from(this.currentUserRef.get()).pipe(map(snapshot => snapshot.exists));
  }

  load() {
    this.idToken = localStorage.getItem(AuthenticationStorageKeys.idToken);
    this.token = localStorage.getItem(AuthenticationStorageKeys.token);

    if (this.token) {
      const userInfoJson = localStorage.getItem(AuthenticationStorageKeys.userInfo);
      const permissionsJson = localStorage.getItem(AuthenticationStorageKeys.permissions);
      try {
        this.userInfo = JSON.parse(userInfoJson);
        if (permissionsJson) {
          this.permissions = JSON.parse(permissionsJson);
        }
      } catch (error) {
        console.error(error);
        this.clear();
      }
    }

    if (this.token) {
      const credential = firebase.auth.GoogleAuthProvider.credential(this.idToken, this.token);
      return from(firebase.auth().signInWithCredential(credential)).pipe(
        switchMap(() => this.getPermissions()),
        catchError(e => {
          this.clear();
          return throwError(e);
        }),
      );
    } else {
      return of(undefined);
    }
  }

  save() {
    return this.checkExist().pipe(
      switchMap(isExisted => {
        if (!isExisted) {
          return from(this.currentUserRef.set(this.userInfo));
        } else {
          return from(this.currentUserRef.update(this.userInfo));
        }
      }),
      tap(() => {
        localStorage.setItem(AuthenticationStorageKeys.idToken, this.idToken);
        localStorage.setItem(AuthenticationStorageKeys.token, this.token);
        localStorage.setItem(AuthenticationStorageKeys.userInfo, JSON.stringify(this.userInfo));
      }),
    );
  }

  clear() {
    localStorage.removeItem(AuthenticationStorageKeys.idToken);
    localStorage.removeItem(AuthenticationStorageKeys.token);
    localStorage.removeItem(AuthenticationStorageKeys.userInfo);
    localStorage.removeItem(AuthenticationStorageKeys.permissions);

    delete this._currentUserRef;
    delete this.idToken;
    delete this.token;
    delete this.userInfo;
  }

  // --------------------------------------------------

  loginWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return from(firebase.auth().signInWithPopup(provider)).pipe(
      map(result => {
        const { user, additionalUserInfo, credential } = result;
        const { uid: googleId, displayName, photoURL, email, phoneNumber } = user;
        // @ts-ignore
        const { given_name: firstName, family_name: lastName } = additionalUserInfo.profile;
        // @ts-ignore
        const { idToken: idToken, accessToken: token } = credential;
        this.idToken = idToken;
        this.token = token;
        this.userInfo = {
          googleId,
          displayName,
          photoURL,
          email,
          phoneNumber,
          firstName,
          lastName,
        };

        return this.userInfo;
      }),
      switchMap(() => this.getUserInfo()),
      switchMap(() => this.getPermissions()),
      switchMap(() => this.save()),
    );
  }

  logout() {
    return from(firebase.auth().signOut()).pipe(
      finalize(() => {
        this.clear();
      }),
    );
  }

  // --------------------------------------------------

  getUserInfo() {
    return from(this.currentUserRef.get()).pipe(
      map(snapshot => {
        const { exists } = snapshot;
        if (exists) {
          const userInfo = snapshot.data();
          this.userInfo = { ...this.userInfo, ...userInfo };
        }
        return this.userInfo;
      }),
    );
  }

  getPermissions() {
    return from(this.permissionsRef.get()).pipe(
      map(snapshot => {
        const { exists } = snapshot;
        if (exists) {
          const permissions = snapshot.data();
          this.permissions = permissions;
        }

        return this.permissions;
      }),
    );
  }
}
