import { render, staticRenderFns } from "./admin.component.vue?vue&type=template&id=45ce8fbb&scoped=true&"
import script from "./admin.component.js?vue&type=script&lang=js&"
export * from "./admin.component.js?vue&type=script&lang=js&"
import style0 from "./admin.component.scss?vue&type=style&index=0&id=45ce8fbb&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45ce8fbb",
  null
  
)

export default component.exports