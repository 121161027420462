import { mapGetters } from 'vuex';
import { QuestionDetail } from '@/modules/user/pages/history-page/components'

export default {
  name: 'ResultV2',

  components: {
    QuestionDetail
  },

  data() {
    return {
      chapters: {},
      subject: '',
      result: {
        AnsOrder: [],
        Answers: [],
        CorrectNum: 0,
        TotalNum: 1,
        SpendTime: 0,
      },
      testCode: '',
      firebase: this.$firebase.rt.ref('user_tracking_v2/' + this.$authentication.userInfo.email.replace('@gmail.com', '').replaceAll('.', '!')),
      questions: [],
      showModal: false,
      quizId: undefined,
    };
  },

  beforeMount() {
    this.resultCode = this.$route.params.resId;
    let resultCodeSplit = this.resultCode.split('_');
    this.subjectCode = resultCodeSplit[0];
    this.testCode = resultCodeSplit[1];

    if (!this.$store.state.SubjectList) {
      this.$subject.ksaSubjectsDoc.onSnapshot(snapshot => {
        let data = snapshot.data();
        this.subject = data[this.subjectCode];
      });
    } else {
      this.subject = this.$store.state.SubjectList[this.subjectCode];
    }
  },

  mounted() {

    document.title = 'Tabbook - Result - V2';

    this.firebase.child('submit').child(this.$route.params.resId)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.result = snapshot.val();
          if (Array.isArray(this.result.Answers)) {
            this.result.Answers.map((answer, index) => {
              if (answer.selected !== answer.correct) {
                if (this.chapters[answer.chapter]) {
                  this.chapters[answer.chapter].push(index + 1);
                } else {
                  this.chapters[answer.chapter] = [index + 1];
                }
              }
            });
          } else {
            let answers = this.result.Answers;
            Object.keys(answers).map(index => {
              if (answers[index].selected !== answers[index].correct) {
                if (this.chapters[answers[index].chapter]) {
                  this.chapters[answers[index].chapter].push(parseInt(index) + 1);
                } else {
                  this.chapters[answers[index].chapter] = [parseInt(index) + 1];
                }
              }
            });
          }
        } else {
          console.log('nothing');
        }
      });
  },

  computed: {
    ...mapGetters(['getResult']),
    setContent() {
      let percent = this.result.CorrectNum / this.result.TotalNum;
      if (percent >= 0.8) return 'Bạn thật là thần thánh!';
      else if (percent < 0.5) return 'Chuẩn bị tiền học lại thôi!';
      else return 'Nice try!';
    },

    getPercent() {
      return Math.round(this.result.CorrectNum / this.result.TotalNum * 100) + '%';
    },

    getTime()  {
      return Math.round(this.result.SpendTime/1000/60) + ' phút ' + Math.round(this.result.SpendTime/1000%60) + ' giây'
    }
  },

  methods: {
    showResultDetail() {
      if (this.questions.length === 0) {
        let temp = this.$route.params.resId.split("_")
        let quizCode = temp[0] + '_' + temp[1]
        this.$firebase.db
          .collection('quiz')
          .doc(quizCode)
          .get()
          .then(res => {
            this.questions = Object.values(res.data());
            for (let i = 0; i < this.questions.length; i++) {
              this.questions[i]['selectAnswer'] = this.result.Answers[i].selected
            }
            this.$bvModal.show('detail-modal')
          }).catch(() => {
          this.$toast.add({severity:'error', summary: 'Failed', detail:'Cannot connect to server', life: 3000});
        });
      } else {
        this.$bvModal.show('detail-modal')
      }
    },

    retest() {
      this.$bvModal.hide('detail-modal');
      let temp = this.$route.params.resId.split('_')
      this.showModal = true
      this.quizId = temp[0] + '_' + temp[1]
    },
  }
};
