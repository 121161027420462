import { firebaseConfig } from '@/configs';
import firebase from 'firebase/app';
import 'firebase/analytics'
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/auth';

import { BaseService } from './base.service';

export { firebase };

export class FirebaseService extends BaseService {
  /** @type {FirebaseService} */
  static _instance;

  // --------------------------------------------------

  static install(Vue) {
    if (!FirebaseService._instance) {
      FirebaseService._instance = new FirebaseService(Vue);
    }

    Vue.prototype.$firebase = FirebaseService._instance;
  }

  // --------------------------------------------------

  /** @type firebase.firestore.Firestore */
  _db;
  _rt;

  get db() {
    return this._db;
  }

  get rt() {
    return this._rt;
  }

  // --------------------------------------------------

  constructor(Vue) {
    super(Vue);

    firebase.initializeApp(firebaseConfig);
    firebase.analytics();


    this._db = firebase.firestore();
    this._rt = firebase.database();
  }
}
