import { BaseService } from './base.service';

export class NotificationService extends BaseService {
  static timeout = 3000;

  /** @type {NotificationService} */
  static _instance;

  // --------------------------------------------------

  static install(Vue) {
    if (!NotificationService._instance) {
      NotificationService._instance = new NotificationService(Vue);
    }

    Vue.prototype.$notification = NotificationService._instance;
  }

  // --------------------------------------------------

  get $toast() {
    return this._vue.prototype.$toast;
  }

  // --------------------------------------------------

  constructor(Vue) {
    super(Vue);
  }

  // --------------------------------------------------

  success(message, title = 'Success') {
    this.$toast.add({ severity: 'success', summary: title, detail: message, life: NotificationService.timeout });
  }

  error(message, title = 'Error') {
    this.$toast.add({ severity: 'error', summary: title, detail: message, life: NotificationService.timeout });
  }
}
