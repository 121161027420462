import { AuthRoute } from '@/modules/authentication';
import moment from 'moment';
import { finalize } from 'rxjs/operators';
import { v4 } from 'uuid';

const AppComponent = {
  data() {
    return { isLoading: true };
  },
  created() {
    this.routingHistory = this.$firebase.rt.ref('routing_histories');

    this.$authentication
      .load()
      .pipe(
        finalize(() => {
          this.isLoading = false;
          if (!this.$authentication.isLogged && this.$route.path !== AuthRoute.Login) {
            const redirectUrl = this.$router.currentRoute.fullPath;
            this.$router.push({ path: AuthRoute.Login, query: { redirectUrl } });
          }
        }),
      )
      .subscribe();
  },
  watch: {
    $route(to, from) {
      const { userInfo } = this.$authentication;
      const email = userInfo?.email;
      if (!email || to.fullPath.startsWith('/admin')) {
        return;
      }

      const now = new Date();

      function getRouteInfo(route) {
        const { fullPath, hash, name, params, query, path } = route;

        return { fullPath, hash, name, params, query, path };
      }

      const fromRoute = getRouteInfo(from);
      const toRoute = getRouteInfo(to);

      const normalizeEmail = email.replaceAll('@', '<>').replaceAll('.', '!');

      const id = `${moment(now).format('yyyy-MM-DD HH:mm:ss:SSS')} - ${v4()}`;
      const ref = this.routingHistory.child(normalizeEmail).child(id);

      ref.set({
        email,
        createdAt: now.toString(),
        from: fromRoute,
        to: toRoute,
      });
    },
  },
};

export default AppComponent;
