
import { QuestionBox } from './components';

export default {
  name: 'TestPage',
  components: {
    QuestionBox: QuestionBox,
  },
  data() {
    return {
      questions: [],
      testCode: '',
      subject: '',
      rating: 0,
      quizCode: '',
      completedAns: 0,
      firebase: this.$firebase.rt.ref("user_tracking/" + this.$authentication.userInfo.email.replace('@gmail.com','').replaceAll('.','!'))
    };
  },

  methods: {
    submit() {
      if (this.$store.state.Subject.Info.Answers.length > 0 ) {
        let temp = this.$store.getters.getTest
        let _answers = temp[this.$store.state.Subject.TestCode].Answers
        if (_answers.includes(undefined) || _answers.length < this.questions.length){
          for (let i = 0; i< this.questions.length; i++) {
            if(!_answers[i]) {
              let ans = {
                index: i,
                info: {
                  selected: '',
                  correct: this.questions[i].correctAnswer,
                  chapter: this.questions[i].chapter,
                },
              }
              this.$store.commit('INSERT_EMPTY_ANSWER',ans)
            }
          }
        }
        temp[Object.keys(temp)[0]]['Rating'] = this.rating
        this.firebase.child('submit').update(temp)
        this.$router.push({
          name: 'Result',
          params: {
            resId: this.$store.state.Subject.TestCode
          }
        })
      } else {
        this.$toast.add({severity:'error', summary: 'Failed', detail:'Hãy chọn các câu trả lời trước khi submit!', life: 3000});
      }
    },
    clicked() {
      this.completedAns += 1
    }
  },

  computed: {
    nQuestions() {
      return this.questions.length;
    },
  },
  beforeMount() {
    this.quizCode = this.$route.params.id
    let quizCodeSplit = this.quizCode.split('_')
    this.testCode = quizCodeSplit[1]

    if (!this.$store.state.SubjectList) {
      this.$subject.ksaSubjectsDoc.onSnapshot(snapshot => {
        let data = snapshot.data()
        this.subject = data[quizCodeSplit[0]]
        this.$store.commit('INIT', data);
      })
    } else {
      this.subject = this.$store.state.SubjectList[quizCodeSplit[0]]
      this.$store.commit('RESET_TEST');
    }
  },

  mounted() {
    document.title = 'Tabbook - Test'

    this.$firebase.db
      .collection('quiz')
      .doc(this.quizCode)
      .get()
      .then(res => {
        this.questions = Object.values(res.data());
        this.$store.commit('SET_TEST_CODE', {id: this.quizCode + '_' + Date.now(), total: this.questions.length})
      }).catch(() => {
      this.$toast.add({severity:'error', summary: 'Failed', detail:'Cannot connect to server', life: 3000});
    });
  },
};
