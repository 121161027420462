import { render, staticRenderFns } from "./result-v2.vue?vue&type=template&id=e8238e32&scoped=true&"
import script from "./result-v2.js?vue&type=script&lang=js&"
export * from "./result-v2.js?vue&type=script&lang=js&"
import style0 from "./result-v2.scss?vue&type=style&index=0&id=e8238e32&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8238e32",
  null
  
)

export default component.exports