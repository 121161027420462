import { BaseService } from '@/modules/core';

export class UserLayoutService extends BaseService {
  /** @type {UserLayoutService} */
  static _instance;

  // --------------------------------------------------

  static install(Vue) {
    if (!UserLayoutService._instance) {
      UserLayoutService._instance = new UserLayoutService(Vue);
    }

    Vue.prototype.$userLayout = UserLayoutService._instance;
  }

  // --------------------------------------------------

  isOpenMobileSidebar = false;

  // --------------------------------------------------

  constructor(Vue) {
    super(Vue);
  }

  // --------------------------------------------------
}
