import { DocumentPage, HistoryPage, ResultPage, ResultPageV2, SubjectsPage, TestPage } from '@/modules/user/pages';
import { QuizPage } from '@/modules/user/pages/quiz-page';

import User from './user.component.vue';

export const UserRoute = {
  Base: '/user',
  Subjects: '/user/subjects',
  TestPage: '/user/test/:id',
  QuizPage: '/user/quiz/:id',
  Result: '/user/result/:resId',
  Document: '/user/documents',
  History: '/user/history',
  ResultV2: '/user/result-v2/:resId',
};

export const userRoutes = [
  {
    path: UserRoute.Base,
    name: 'User',
    component: User,
    redirect: UserRoute.Subjects,
    children: [
      {
        path: UserRoute.Subjects,
        name: 'Subjects',
        component: SubjectsPage,
      },
      {
        path: UserRoute.TestPage,
        name: 'Test',
        component: TestPage,
      },
      {
        path: UserRoute.QuizPage,
        name: 'Quiz',
        component: QuizPage,
      },
      {
        path: UserRoute.Result,
        name: 'Result',
        component: ResultPage,
      },
      {
        path: UserRoute.Document,
        name: 'Document',
        component: DocumentPage,
      },
      {
        path: UserRoute.History,
        name: 'History',
        component: HistoryPage,
      },
      {
        path: UserRoute.ResultV2,
        name: 'ResultV2',
        component: ResultPageV2,
      },
      { path: '*', redirect: UserRoute.Subjects },
    ],
  },
];
