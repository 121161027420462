import subjectData from '@/data/subjects.json';
import { BaseService } from '@/modules/core';
import firebase from 'firebase/app';
import { from } from 'rxjs';

export class SubjectService extends BaseService {
  /** @type {SubjectService} */
  static _instance;

  // --------------------------------------------------

  static install(Vue) {
    if (!SubjectService._instance) {
      SubjectService._instance = new SubjectService(Vue);
    }

    Vue.prototype.$subject = SubjectService._instance;
  }

  // --------------------------------------------------

  subjectData = subjectData;

  ksaSubjectsDoc;

  // --------------------------------------------------

  /** @type {import('@/modules/core').FirebaseService} */
  get $firebase() {
    return this._vue.prototype.$firebase;
  }

  // --------------------------------------------------

  constructor(Vue) {
    super(Vue);

    this.ksaSubjectsDoc = this.$firebase.db.collection('subject_list').doc('KINHTE');
    this.quizCollection = this.$firebase.db.collection('quiz');
  }

  // --------------------------------------------------

  getListQuizzes(subjectId) {
    const text = `${subjectId}_`;
    const end = text.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
    const id = firebase.firestore.FieldPath.documentId();
    return from(this.quizCollection.where(id, '>=', text).where(id, '<', end).get());
  }
}
