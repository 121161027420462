import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Email: '',
    SubjectList: null,
    Subject: {
      TestCode: '',
      Info: {
        TotalNum: 0,
        CorrectNum: 0,
        Answers: [],
        SpendTime: 0,
        AnsOrder: [],
      },
    },
    StartTime: 0,
  },

  getters: {
    getTest: state => {
      let test = {};
      test[state.Subject.TestCode] = state.Subject.Info;
      return test;
    },

    getResult: state => {
      return {
        testCode: state.Subject.TestCode.split('_')[1],
        correct: state.Subject.Info.CorrectNum,
        total: state.Subject.Info.TotalNum,
        answers: state.Subject.Info.Answers,
      };
    },
  },

  mutations: {
    INIT(state, subjectList) {
      state.SubjectList = subjectList;
      state.Subject = {
        TestCode: '',
        Info: {
          TotalNum: 0,
          CorrectNum: 0,
          Answers: [],
          SpendTime: 0,
          AnsOrder: [],
        },
      };
    },

    RESET_TEST(state) {
      state.Subject = {
        TestCode: '',
        Info: {
          TotalNum: 0,
          CorrectNum: 0,
          Answers: [],
          SpendTime: 0,
          AnsOrder: [],
        },
      };
    },

    SET_USER_EMAIL(state, email) {
      state.Email = email;
    },

    SET_SUB_ID(state, id) {
      state.Subject.Id = id;
    },

    SET_TEST_CODE(state, test) {
      state.Subject.TestCode = test.id;
      state.Subject.Info.Answers = [];
      state.Subject.Info.TotalNum = test.total;
      state.StartTime = Date.now();
    },

    ADD_ANSWER(state, answer) {
      state.Subject.Info.Answers[answer.id - 1] = answer.info;
      state.Subject.Info.CorrectNum += answer.correctNum;
      state.Subject.Info.AnsOrder.push(answer.id - 1);
      state.Subject.Info.SpendTime = Date.now() - state.StartTime;
    },

    ADD_ANSWER2(state, answer) {
      if (!state.Subject.Info.Answers[answer.id - 1]) {
        state.Subject.Info.Answers[answer.id - 1] = answer.info;
        state.Subject.Info.AnsOrder.push(answer.id - 1);
        state.Subject.Info.SpendTime = Date.now() - state.StartTime;
      } else {
        state.Subject.Info.Answers[answer.id - 1] = answer.info;
        state.Subject.Info.SpendTime = Date.now() - state.StartTime;
      }
    },

    REMOVE_ANSWER2(state, answer) {
      if (state.Subject.Info.Answers[answer.id - 1]) {
        delete state.Subject.Info.Answers[answer.id - 1];
        state.Subject.Info.AnsOrder = state.Subject.Info.AnsOrder.filter(x => x !== answer.id - 1);
      }
    },

    INSERT_EMPTY_ANSWER(state, answer) {
      state.Subject.Info.Answers[answer.index] = answer.info;
    },
  },
  actions: {},
  modules: {},
});
