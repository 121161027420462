/*// // For DEV
export const firebaseConfig = {
  apiKey: 'AIzaSyAVsPyxihZmBR7JX8zoScoyLzb9ZZ_i7ZA',
  authDomain: 'tab-stupid.firebaseapp.com',
  databaseURL: 'https://tab-stupid-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'tab-stupid',
  storageBucket: 'tab-stupid.appspot.com',
  messagingSenderId: '878862725184',
  appId: '1:878862725184:web:a239c719e93e17ed7a6fa5',
  measurementId: 'G-WKGV2FJNCC',
};*/

// For PROD
 export const firebaseConfig = {
   apiKey: 'AIzaSyBqoYS-IirRZTi8yRv-L3TM8ithrgwdQ7k',
   authDomain: 'on-thi-tabbook.firebaseapp.com',
   databaseURL: 'https://on-thi-tabbook-default-rtdb.asia-southeast1.firebasedatabase.app',
   projectId: 'on-thi-tabbook',
   storageBucket: 'on-thi-tabbook.appspot.com',
   messagingSenderId: '90428985257',
   appId: '1:90428985257:web:d647b35070614ca6007eb8',
   measurementId: 'G-P2106X61C8',
 };

/*// For STAGING
export const firebaseConfig = {
  apiKey: "AIzaSyBKzrKjWTkoun150R9qFplWMfsJZLZiez8",
  authDomain: "tabbook-staging.firebaseapp.com",
  databaseURL: 'https://tabbook-staging-default-rtdb.asia-southeast1.firebasedatabase.app/',
  projectId: "tabbook-staging",
  storageBucket: "tabbook-staging.appspot.com",
  messagingSenderId: "706816096077",
  appId: "1:706816096077:web:284033a79b027e807ce904",
  measurementId: "G-GTN8KF8ZCH"
};*/
