export default {
  props: ['question'],
  data() {
    return {
      clicked: false,
      selectedValue: null,
      firebase: this.$firebase.rt.ref("user_tracking/" + this.$authentication.userInfo.email.replace('@gmail.com','').replaceAll('.','!'))
    }
  },
  methods: {
    getAnswers(answers) {
      return Object.keys(answers)
        .sort()
        .map(answer => {
          return {
            text: answers[answer],
            value: answer,
          };
        });
    },

    checkAnswer(value) {
      return this.question.correctAnswer === value
    },

    answerClass(value) {
      let answerClass = ''
      if (this.clicked && this.question.correctAnswer === value) {
        answerClass = 'correct'
      } else if (this.clicked && this.selectedValue === value && this.question.correctAnswer !== value) {
        answerClass = 'incorrect'
      }
      return answerClass
    },

    selectAnswer(value, chapter) {
      if (!this.clicked) {
        this.selectedValue = value
        let answer = {
          id: this.question.id,
          info: {
            selected: value,
            correct: this.question.correctAnswer,
            chapter: chapter,
          },
          correctNum: this.question.correctAnswer === value ? 1: 0
        }
        this.$store.commit('ADD_ANSWER', answer)
        this.firebase.child('temp').update(this.$store.getters.getTest)
        this.$emit('clicked')
      }
      this.clicked = true;
    }
  },
}
