import { mapGetters } from 'vuex';

export default {
  name: 'Result',

  data() {
    return {
      chapters: {},
      subject: '',
      result: {
        AnsOrder: [],
        Answers: [],
        CorrectNum: 0,
        TotalNum: 1,
        SpendTime: 0,
      },
      testCode: '',
      firebase: this.$firebase.rt.ref('user_tracking/' + this.$authentication.userInfo.email.replace('@gmail.com', '').replaceAll('.', '!')),
    };
  },

  beforeMount() {
    this.resultCode = this.$route.params.resId;
    let resultCodeSplit = this.resultCode.split('_');
    this.subjectCode = resultCodeSplit[0];
    this.testCode = resultCodeSplit[1];

    if (!this.$store.state.SubjectList) {
      this.$subject.ksaSubjectsDoc.onSnapshot(snapshot => {
        let data = snapshot.data();
        this.subject = data[this.subjectCode];
      });
    } else {
      this.subject = this.$store.state.SubjectList[this.subjectCode];
    }
  },

  mounted() {

    document.title = 'Tabbook - Result';

    this.firebase.child('submit').child(this.$route.params.resId)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.result = snapshot.val();
          if (Array.isArray(this.result.Answers)) {
            this.result.Answers.map((answer, index) => {
              if (answer.selected !== answer.correct) {
                if (this.chapters[answer.chapter]) {
                  this.chapters[answer.chapter].push(index + 1);
                } else {
                  this.chapters[answer.chapter] = [index + 1];
                }
              }
            });
          } else {
            let answers = this.result.Answers;
            Object.keys(answers).map(index => {
              if (answers[index].selected !== answers[index].correct) {
                if (this.chapters[answers[index].chapter]) {
                  this.chapters[answers[index].chapter].push(parseInt(index) + 1);
                } else {
                  this.chapters[answers[index].chapter] = [parseInt(index) + 1];
                }
              }
            });
          }
        } else {
          console.log('nothing');
        }
      });
  },

  computed: {
    ...mapGetters(['getResult']),
    setContent() {
      let percent = this.result.CorrectNum / this.result.TotalNum;
      if (percent >= 0.8) return 'Bạn thật là thần thánh!';
      else if (percent < 0.5) return 'Chuẩn bị tiền học lại thôi!';
      else return 'Nice try!';
    },
    getPercent() {
      return Math.round(this.result.CorrectNum / this.result.TotalNum * 100) + '%';
    },
  },
};
