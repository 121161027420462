export default {
  name: 'DocumentPage',
  methods: {
    subscribe() {
      this.$firebase.db.collection('subscribers')
        .doc(this.$authentication.userInfo.email)
        .set({subscribe: true})
        .then(() => {
          this.$bvModal.show('thanksModal')
        })
    }
  },
  mounted() {
    document.title = 'Tabbook - Summary'
  }
}
