import Admin from './admin.component.vue';
import { UploadTest } from './pages';

export const AdminRoute = {
  Base: '/admin',
  UploadPage: '/admin/upload',
  UploadPageWithId: '/admin/upload/:id',
};

export const adminRoutes = [
  {
    path: AdminRoute.Base,
    name: 'Admin',
    component: Admin,
    children: [
      {
        path: AdminRoute.UploadPage,
        name: 'UploadPage',
        component: UploadTest,
      },
      {
        path: AdminRoute.UploadPageWithId,
        name: 'UploadWithId',
        component: UploadTest,
      },
    ],
  },
];
