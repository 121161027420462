import {TableSchemas} from '@/configs';
import { Test } from './components'

const AdminComponent = {
  data() {
    return {
      isAdminPage: true,
      subjects: [],
    }
  },

  components: {
    Test: Test,
  },

  created() {
    if (!this.isAdmin) {
      this.$router.push('/404');
    }
  },

  updated() {
    this.isAdminPage = typeof this.$refs.uploadPage === 'undefined';
  },

  mounted() {
    this.isAdminPage = typeof this.$refs.uploadPage === 'undefined';
    if (this.isAdminPage) {
      this.$firebase.db.collection(TableSchemas.SubjectList).doc('KINHTE').get()
        .then((res) => {
          let _subject = res.data()
          Object.keys(_subject).map((key) =>{
            this.subjects.push(
              {
                id: key,
                title: _subject[key],
                test: [],
              }
            )
          })
        })
        .then(() => {
          this.$firebase.db.collection(TableSchemas.Subjects).get()
            .then((res) => {
              let _test = res.docs.map(doc => {
                return {
                  id : doc.id,
                  test: {...doc.data()}
                }
              })
              let index
              _test.forEach(subject => {
                index = this.subjects.findIndex((e) => e.id === subject.id)
                Object.keys(subject.test).map((key) => {
                  this.subjects[index].test.push({
                    code: key,
                    info: subject.test[key]
                  })
                })
              })
            })
        })
        .catch(()=>{
          this.$toast.add({severity:'error', summary: 'Failed', detail:'Cannot connet to server', life: 3000});
        })
    }
  },

  computed: {
    isAdmin() {
      return this.$authentication.isAdmin;
    },
  },

  methods: {

    signOut() {
      localStorage.clear();
      location.reload();
    },

    synchronize() {
      Object.values(TableSchemas).forEach(key => {
        this.$firebase.db.collection(key).get().then((res) => {
          let t = {}
          res.docs.map(doc => t[doc.id.replaceAll('.','!')] = doc.data())
          this.$firebase.rt.ref(key).set(t)
        })
      })
    },

    synchronize2() {
      Object.values(TableSchemas).forEach(col => {
        this.$firebase.rt.ref(col).get().then((snapshot => {
          if (snapshot.exists()) {
            let data = snapshot.val()
            Object.keys(data).forEach(key => {
              if (col === 'quiz') {
                data[key] = { ...data[key] }
              } else if (col === 'subjects') {
                Object.keys(data[key]).forEach(key1 => {
                  data[key][key1].chapters = {...data[key][key1].chapters}
                })
              }
              this.$firebase.db.collection(col).doc(key.replaceAll('!','.')).set(data[key]).catch(err => {
                console.log(err)
              })
            })
            //
          } else {
            console.log("No data available");
          }
        }))
      })
    }
  },

};

export default AdminComponent;
