import { BaseService } from '@/modules/core';

export class UserTrackingService extends BaseService {
  /** @type {UserTrackingService} */
  static _instance;

  // --------------------------------------------------

  static install(Vue) {
    if (!UserTrackingService._instance) {
      UserTrackingService._instance = new UserTrackingService(Vue);
    }

    Vue.prototype.$userTracking = UserTrackingService._instance;
  }

  /** @type {import('@/modules/core').FirebaseService} */
  get $firebase() {
    return this._vue.prototype.$firebase;
  }

  get $authentication() {
    return this._vue.prototype.$authentication;
  }

  // --------------------------------------------------
  _user
  get user() {
    const _email = this.$authentication.userInfo.email.replace('.','')
    this._user = this.$firebase.rt.ref("user_tracking/" + _email);

    return this._user;
  }


  // --------------------------------------------------

  constructor(Vue) {
    super(Vue);
  }

  // --------------------------------------------------
}
