import { QuestionDetail } from './components'

export default {
  name: 'History',
  components: {
    QuestionDetail: QuestionDetail
  },
  data(){
    return {
      subjects: [],
      firebase: this.$firebase.rt.ref("user_tracking/" + this.$authentication.userInfo.email.replace('@gmail.com','').replaceAll('.','!')),
      firebaseV2: this.$firebase.rt.ref("user_tracking_v2/" + this.$authentication.userInfo.email.replace('@gmail.com','').replaceAll('.','!')),
      subjectDetail: {},
      questions: [],
      showModal: false,
      quizId: undefined,
    }
  },

  mounted() {
    if (!this.$store.state.SubjectList) {
      this.$firebase.db.collection('subject_list').doc('KINHTE')
        .get().then(res => {
        this.$store.commit('INIT', res.data());
      }).then(() => {
        this.getHistories()
      })
      /*this.$subject.ksaSubjectsDoc.onSnapshot(snapshot => {
        this.$store.commit('INIT', snapshot.data());
      })*/
    } else {
      this.getHistories()
    }
    document.title = 'Tabbook - History'
  },

  methods: {
    getHistories() {
      this.firebase.child('submit').get().then(snapshot => {
        if (snapshot.exists()) {
          let data = snapshot.val();
          this.subjects = Object.keys(data).map(key => {
            let temp = key.split('_')
            return {
              subject: this.$store.state.SubjectList[temp[0]],
              testCode: temp[1],
              info: data[key],
              quiz: temp[0] + '_' + temp[1],
              date: temp[2],
            }
          })
        }
      }).then(() => {
        this.firebaseV2.child('submit').get().then(snapshot => {
          if (snapshot.exists()) {
            let data = snapshot.val();
            let temp = Object.keys(data).map(key => {
              let temp = key.split('_')
              return {
                subject: this.$store.state.SubjectList[temp[0]],
                testCode: temp[1],
                info: data[key],
                quiz: temp[0] + '_' + temp[1],
                date: temp[2],
              }
            })
            Array.prototype.push.apply(this.subjects, temp)
            this.subjects = [...this.subjects]
          } else {
            if (this.subjects.length === 0) {
              this.$notification.error('Bạn vẫn chưa thực hiện bài ôn tập nào')
            }
          }
        })
      })
    },

    showDetail(subject) {
      this.subjectDetail = subject
      this.$bvModal.show('detail-modal')
      this.$firebase.db
        .collection('quiz')
        .doc(subject.quiz)
        .get()
        .then(res => {
          this.questions = Object.values(res.data());
        }).catch(() => {
        this.$toast.add({severity:'error', summary: 'Failed', detail:'Cannot connect to server', life: 3000});
      });
    },

    getPercent(correct, total) {
      return Math.round(correct/total *100) + '%'
    },

    retest(quiz) {
      this.$bvModal.hide('detail-modal')
      this.showModal = true
      this.quizId = quiz
    },

    getDay(time) {
      let d = new Date(time)
      return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
    },

    getTime(time)  {
      return Math.round(time/1000/60) + ' phút ' + Math.round(time/1000%60) + ' giây'
    }
  },

}
