import { TableSchemas } from '@/configs';

const UploadTest = {
  name: 'UploadTest',
  data() {
    return {
      quillOption: {},
      subject: {
        title: '',
        code: '',
        chapters: {},
      },
      question: {
        id: '1',
        chapter: '',
        content: '',
        answers: {
          a: '',
          b: '',
          c: '',
          d: '',
        },
        correctAnswer: '',
      },
      questions: [],
      addMode: true,
      editingQuestion: null,
      trueFalseMode: false,
      subjectOption: [],
      testId: null,
    };
  },

  methods: {
    addNewQuestion() {
      let temp = Object.assign({}, this.question);
      this.questions.push(temp);
      if (this.question.chapter in this.subject.chapters) {
        this.subject.chapters[this.question.chapter] += 1;
      } else {
        this.subject.chapters[this.question.chapter] = 1;
      }
      this.emptyQuestion();
    },

    retrieveQuestion(ques) {
      this.trueFalseMode = ques.correctAnswer === 'true' || ques.correctAnswer === 'false';
      this.question = ques;
      this.addMode = false;
    },

    emptyQuestion() {
      this.question = {
        id: this.questions.length + 1,
        chapter: '',
        content: '',
        answers: {
          a: '',
          b: '',
          c: '',
          d: '',
        },
        correctAnswer: '',
      };
    },

    editQuestion() {
      let index = this.questions.findIndex(q => q.id === this.question.id);
      this.questions[index] = this.question;
      this.emptyQuestion();
      this.addMode = true;
    },

    delQuestion() {
      let index = this.questions.findIndex(q => q.id === this.question.id);
      this.questions.splice(index, 1);
      let newId;
      for (let i = index; i < this.questions.length; i++) {
        newId = parseInt(this.questions[i].id) - 1;
        this.questions[i].id = newId.toString();
      }
      if (this.subject.chapters[[this.question.chapter]] === 1) {
        delete this.subject.chapters[[this.question.chapter]];
      }

      this.emptyQuestion();
      this.addMode = true;
    },

    cancelEditMode() {
      this.emptyQuestion();
      this.addMode = true;
    },

    hideModal() {
      this.$refs['preview-modal'].hide();
    },

    saveQuiz() {
      let subjectID = this.subjectOption.find(x => x.value === this.subject.title).id;
      let testInfo = {};
      testInfo[this.subject.code] = {
        chapters: this.subject.chapters,
        updatedAt: Date(),
        createdAt: Date(),
      };
      this.$firebase.db
        .collection('quiz')
        .doc(subjectID + '_' + this.subject.code)
        .set({ ...this.questions })
        .then(() => {
          this.$firebase.db
            .collection('subjects')
            .doc(subjectID)
            .update(testInfo)
            .then(() => {
              this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Order submitted', life: 3000 });
              this.$router.push({ name: 'Admin' });
            })
            .catch(() => {
              this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Cannot connet to server', life: 3000 });
            });
        })
        .catch(() => {
          this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Cannot connet to server', life: 3000 });
        });
    },
  },

  computed: {
    questionType() {
      return this.trueFalseMode ? 'primary' : 'secondary';
    },
    questionList() {
      for (let i = 0; i < this.questions.length; i++) {
        this.questions[i].id = i + 1;
      }
      return this.questions;
    },
    showChapters() {
      let content = '';
      for (const chapter in this.subject.chapters) {
        content += 'Chương ' + chapter + ': ' + this.subject.chapters[chapter];
      }
      return content;
    },
  },

  created() {
    this.$firebase.db
      .collection(TableSchemas.SubjectList)
      .doc('KINHTE')
      .get()
      .then(res => {
        let _subject = res.data();
        Object.keys(_subject).map(key => {
          this.subjectOption.push({
            id: key,
            value: _subject[key],
            text: _subject[key],
          });
        });
        this.testId = this.$route.params.id;
        if (this.testId) {
          let temp = this.testId.split('_');
          this.subject.code = temp[1];
          temp = this.subjectOption.find(option => option.id === temp[0]);
          this.subject.title = temp.text;

          this.$firebase.db
            .collection('quiz')
            .doc(this.testId)
            .get()
            .then(res => {
              let data = res.data();
              this.questions = Object.keys(data).map(question => {
                return data[question];
              });
              this.questions.forEach(question => {
                if (question.chapter in this.subject.chapters) {
                  this.subject.chapters[question.chapter] += 1;
                } else {
                  this.subject.chapters[question.chapter] = 1;
                }
              });
            })
            .catch(() => {
              this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Cannot connet to server', life: 3000 });
            });
        }
      })
      .catch(() => {
        this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Cannot connet to server', life: 3000 });
      });
  },

  mounted() {},
};

export default UploadTest;
