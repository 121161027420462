import '@/assets/scss/index.scss';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

import { App } from '@/modules/app';
import { AuthenticationService } from '@/modules/authentication';
import { initializeCore } from '@/modules/core';
import { SubjectService } from '@/modules/shared';
import { UserLayoutService } from '@/modules/user';
import Vue from 'vue';
import VueQuillEditor from 'vue-quill-editor';

import router from './router';
import store from './store';

initializeCore();

Vue.config.productionTip = false;

Vue.use(VueQuillEditor, {
  formats: [
    'bold',
    'color',
    'code',
    'bold',
    'italic',
    'strike',
    'script',
    'underline',
    'blockquote',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula',
    'image',
  ],
});
Vue.use(AuthenticationService);
Vue.use(SubjectService);
Vue.use(UserLayoutService);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
